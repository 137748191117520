import React from 'react'
//import makeStyles from '@material-ui/core/styles/makeStyles'
//import styles from './About.styles'
//import clsx from 'clsx'

import PageHeader from '../../../../components/Page/PageHeader'
import PageHeaderTypography from '../../../../components/Page/PageHeaderTypography'
import PageMain from '../../../../components/Page/PageMain'

//const useStyles = makeStyles(styles, {
//    name: About.name,
//})

export interface IAboutProps {

}

export default function About(props: IAboutProps) {
    //const classes = useStyles()

    return (
        <>

            <PageHeader>
                <PageHeaderTypography>О программе</PageHeaderTypography>
            </PageHeader>

            <PageMain>
                О программе
            </PageMain>

        </>
    )
}
