import React from 'react'

import { createCode, ICode } from '../../../../app-codes/services/CodesService'

import PageDialog from '../../../../../components/PageDialog/PageDialog'
import PageDialogHeader from '../../../../../components/PageDialog/PageDialogHeader'
import Container from '@material-ui/core/Container'
import CodelistItemForm from '../../CodelistItemForm/CodelistItemForm'
import Button from '@material-ui/core/Button'

export interface ICreateItemProps {
    onClose: () => void
    onSuccess: (code: ICode) => void
}

export default function CreateItem(props: ICreateItemProps) {
    const { onClose, onSuccess } = props

    const [ data, setData ] = React.useState<Partial<ICode>>({})

    const [ submitPending, setSubmitPending ] = React.useState(false)

    const handleSubmit = () => {
        setSubmitPending(true)
        createCode(data)
            .then((code) => {
                onSuccess(code)
            })
        ;
    }

    return (
        <PageDialog
            open
            onClose={onClose}
        >

            <PageDialogHeader
                onClose={onClose}
            >
                Добавить запись о сертификате
            </PageDialogHeader>

            <Container maxWidth="md">

                <CodelistItemForm
                    data={data}
                    onChangeData={setData}
                    disabled={submitPending}
                />

                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={submitPending}
                    onClick={handleSubmit}
                >
                    Добавить запись
                </Button>

            </Container>

        </PageDialog>
    )
}
