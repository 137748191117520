import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from './Code.styles'
import clsx from 'clsx'

import { useParams } from 'react-router-dom'
import { fetchCode, fetchCodelist, ICode } from '../../../app-codes/services/CodesService'

import PageHeader from '../../../../components/Page/PageHeader'
import PageHeaderTypography from '../../../../components/Page/PageHeaderTypography'
import PageProgress from '../../../../components/Page/PageProgress'
import PageMain from '../../../../components/Page/PageMain'
import PageFooter from '../../../../components/Page/PageFooter'

import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Toolbar from '@material-ui/core/Toolbar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { NavLink } from 'react-router-dom'

const useStyles = makeStyles(styles, {
    name: Code.name,
})

export default function Code() {
    const classes = useStyles()

    const { id } = useParams<{ id:string }>()

    React.useEffect(() => {
        localStorage.setItem('path', `/codes/code/${id}`)
        return () => {
            localStorage.removeItem('path')
        }
    }, [id])

    const [ code, setCode ] = React.useState<ICode | null>()
    const [ codePending, setCodePending ] = React.useState(true)

    React.useEffect(() => {
        setCodePending(true)
        fetchCode(id)
            .then((code) => {
                setCode(code)
                setCodePending(false)
            })
        ;
    }, [id])

    const [ codelist, setCodelist ] = React.useState<ICode[] | null>(null)
    const [ codelistPending, setCodelistPending ] = React.useState(true)

    React.useEffect(() => {
        if (!codelistPending) {
            return
        }
        let active = true
        fetchCodelist({})
            .then((codelist) => {
                if (active) {
                    setCodelist(codelist)
                    setCodelistPending(false)
                }
            })
        ;
        return () => {
            active = false
        }
    }, [codelistPending])

    const pending = React.useMemo(() => {
        return codePending || codelistPending
    }, [codePending, codelistPending])

    const [ menuOpen, setMenuOpen ] = React.useState(false)
    const menuAnchorRef = React.useRef<HTMLButtonElement>(null)

    return (
        <>

            <PageHeader>

                <PageHeaderTypography>Сертификат о вакцинации</PageHeaderTypography>

                <div style={{ flexGrow:1 }}/>

                <IconButton
                    ref={menuAnchorRef}
                    edge="end"
                    onClick={() => setMenuOpen(true)}
                >
                    <MoreVertIcon/>
                </IconButton>

                {menuAnchorRef.current && (
                    <Menu
                        anchorEl={menuAnchorRef.current}
                        open={menuOpen}
                        onClose={() => setMenuOpen(false)}
                    >

                        <MenuItem
                            component={NavLink}
                            to={`/codes/codelist/${id}/update`}
                            onClick={() => { setMenuOpen(false); }}
                        >
                            <ListItemText
                                primary="Изменить"
                            />
                        </MenuItem>

                        <MenuItem
                            component={NavLink}
                            to={`/codes/codelist/${id}/delete`}
                            onClick={() => { setMenuOpen(false); }}
                        >
                            <ListItemText
                                primary="Удалить"
                                primaryTypographyProps={{
                                    color: 'error'
                                }}
                            />
                        </MenuItem>

                    </Menu>
                )}

            </PageHeader>

            {pending && (
                <PageProgress/>
            )}

            {!pending && (
                <PageMain classes={{ root:classes.main }}>
                    {code && code.imageUrl ? (
                        <img className={classes.mainImage} src={code.imageUrl} alt=""/>
                    ) : (
                        <svg className={clsx(classes.mainImage, classes.mainImage_empty)} viewBox="0 0 250 250"><rect x="2" y="2" width="245" height="245" fill="transparent"/><path d="M2 2H37V7H7V37H2V2Z" fill="black"/><path d="M212 2H247V37H242V7H212V2Z" fill="black"/><path d="M7 212H2V247H37V242H7V212Z" fill="black"/><path d="M212 242V247H247V212H242V242H212Z" fill="black"/></svg>
                    )}
                </PageMain>
            )}

            {!pending && (
                <PageFooter>
                    <Toolbar className={classes.footerToolbar} disableGutters>
                        {codelist && codelist.length > 1 && (
                            <Tabs
                                className={classes.footerTabs}
                                variant="scrollable"
                                value={id}
                            >
                                {codelist.map((code) => (
                                    <Tab
                                        key={code.id}
                                        className={classes.footerTab}
                                        label={code.name}
                                        value={code.id}
                                        component={NavLink}
                                        to={`/codes/code/${code.id}`}
                                        replace
                                    />
                                ))}
                            </Tabs>
                        )}
                    </Toolbar>
                </PageFooter>
            )}

        </>
    )
}
