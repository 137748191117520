import { Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({

    back: {
        marginRight: theme.spacing(1.5),
    },

    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },

})

export default styles
