import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from './PageFooterFab.styles'
//import clsx from 'clsx'

const useStyles = makeStyles(styles, {
    name: PageFooterFab.name,
})

export interface IPageFooterFabProps {
    children: React.ReactNode
}

export default function PageFooterFab(props: IPageFooterFabProps) {
    const classes = useStyles(props)

    const { children } = props

    return (
        <div className={classes.root}>
            {children}
        </div>
    )
}
