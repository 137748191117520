import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from './CodelistItemForm.styles'
//import clsx from 'clsx'

import { ICode } from '../../../app-codes/services/CodesService'

import TextField from '@material-ui/core/TextField'
import FileField from '../../../../components/FileField/FileField'
import DateField from '../../../../components/DateField/DateField'

const useStyles = makeStyles(styles, {
    name: CodelistItemForm.name,
})

export interface ICodelistItemFormProps {
    data: Partial<ICode>
    onChangeData: (data: Partial<ICode>) => void
    disabled?: boolean
}

export default function CodelistItemForm(props: ICodelistItemFormProps) {
    const classes = useStyles()

    const { data, onChangeData, disabled } = props

    return (
        <form className={classes.root}>

            <div className={classes.fieldContainer}>
                <TextField
                    label="Название (чтобы отличать один от другого)"
                    name="name"
                    variant="outlined"
                    fullWidth
                    disabled={disabled}
                    value={data.name || ''}
                    onChange={({ target:{ value }}) => {
                        onChangeData({ ...data, name:value })
                    }}
                />
            </div>

            <div className={classes.fieldContainer}>
                <DateField
                    value={data.expiredAt || null}
                    onChangeValue={(value) => {
                        const expiredAt = value ? value.toJSON() : undefined
                        onChangeData({ ...data, expiredAt })
                    }}
                />
            </div>

            <div className={classes.fieldContainer}>
                <TextField
                    label="Адрес страницы на Госуслугах (необязательно)"
                    name="url"
                    variant="outlined"
                    fullWidth
                    disabled={disabled}
                    value={data.url || ''}
                    onChange={({ target:{ value }}) => {
                        onChangeData({ ...data, url:value })
                    }}
                />
            </div>

            <div className={classes.fieldContainer}>
                <FileField
                    label="Адрес изображения"
                    name="url"
                    variant="outlined"
                    fullWidth
                    disabled={disabled}
                    value={data.imageUrl || ''}
                    onChangeValue={(value) => {
                        onChangeData({ ...data, imageUrl:value })
                    }}
                />
            </div>

        </form>
    )
}
