import React from 'react'
//import makeStyles from '@material-ui/core/styles/makeStyles'
//import styles from './PageDialog.styles'
//import clsx from 'clsx'

import Dialog from '@material-ui/core/Dialog'

//const useStyles = makeStyles(styles, {
//    name: PageDialog.name,
//})

export interface IPageDialogProps {
    open: boolean
    onClose: () => void
    children: React.ReactNode
}

export default function PageDialog(props: IPageDialogProps) {
    //const classes = useStyles()

    const { open, onClose, children } = props

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen
        >
            {children}
        </Dialog>
    )
}
