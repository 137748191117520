import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from './PageList.styles'
//import clsx from 'clsx'

import List from '@material-ui/core/List'

const useStyles = makeStyles(styles, {
    name: PageList.name,
})

export interface IPageListProps {
    children: React.ReactNode
}

export default function PageList(props: IPageListProps) {
    const classes = useStyles()

    const { children } = props

    return (
        <List
            className={classes.root}
        >
            {children}
        </List>
    )
}
