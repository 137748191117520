import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from './PageDialogHeader.styles'
//import clsx from 'clsx'

import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles(styles, {
    name: PageDialogHeader.name,
})

export interface IPageDialogHeaderProps {
    onClose: () => void
    children: React.ReactNode
}

export default function PageDialogHeader(props: IPageDialogHeaderProps) {
    const classes = useStyles()

    const { onClose, children } = props

    return (
        <AppBar position="sticky" color="default" elevation={1}>
            <Container maxWidth={false}>
                <Toolbar disableGutters>

                    <IconButton
                        className={classes.back}
                        edge="start"
                        color="primary"
                        onClick={onClose}
                    >
                        <ArrowBackIcon/>
                    </IconButton>

                    <Typography className={classes.text} variant="h6">
                        {children}
                    </Typography>

                </Toolbar>
            </Container>
        </AppBar>
    )
}
