import { Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({

    root: {
        paddingTop: 16,
        paddingBottom: 16,
    },

    fieldContainer: {
        paddingTop: 16,
        paddingBottom: 16,
    },

})

export default styles
