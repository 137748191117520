import React from 'react'

import { PageContext } from './Page'

export interface IPageHeaderProps {
    children: React.ReactNode
}

export default function PageHeader(props: IPageHeaderProps) {

    const pageContext = React.useContext(PageContext)

    React.useEffect(() => {
        pageContext?.setContent(props.children)
        return () => {
            pageContext?.setContent(null)
        }
    }, [
        pageContext?.setContent,
        props.children,
    ])

    return null
}
