import { Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({

    status: {

    },
    status_valid: {
        color: theme.palette.success.dark,
    },
    status_invalid: {
        color: theme.palette.error.dark,
    },

    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    },

    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },

})

export default styles
