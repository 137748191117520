import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from './PageFooter.styles'
//import clsx from 'clsx'

const useStyles = makeStyles(styles, {
    name: PageFooter.name,
})

export interface IPageFooterProps {
    children: React.ReactNode
}

export default function PageFooter(props: IPageFooterProps) {
    const classes = useStyles(props)

    const { children } = props

    return (
        <footer className={classes.root}>
            {children}
        </footer>
    )
}
