import { Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({

    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },

})

export default styles
