import { Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({

    header: {

    },
    headerContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    },
    headerMenu: {
        marginRight: theme.spacing(0.5),
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(1.5),
        },
    },

    menu: {

    },
    menuLogo: {
        width: 40,
        height: 40,
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            width: 48,
            height: 48,
            marginLeft: theme.spacing(-1.5),
            marginRight: theme.spacing(1.5),
        },
    },
    menuMain: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    menuList: {
        paddingTop: 12,
        paddingBottom: 12,
    },
    menuListItem: {
        height: 48,
        paddingLeft: 16,
        paddingRight: 24,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 24,
        }
    },
    menuListItemIcon: {
        minWidth: 40,
        [theme.breakpoints.up('sm')]: {
            minWidth: 48,
        }
    },
    menuListItemText: {
        '& > span': {
            lineHeight: '1px',
            whiteSpace: 'nowrap',
        }
    }

})

export default styles
