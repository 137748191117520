import { Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({

    root: {
        position: 'relative',
    },

    progress: {
        position: 'absolute',
        width: '100%',
    },

})

export default styles
