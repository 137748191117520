import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from './FileField.styles'

import TextField, { StandardTextFieldProps, FilledTextFieldProps, OutlinedTextFieldProps } from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import AttachFileIcon from '@material-ui/icons/AttachFile'

const useStyles = makeStyles(styles, {
    name: FileField.name,
})

export interface IStandardFileFieldProps extends StandardTextFieldProps {
    onChangeValue: (value?: string) => void
}

export interface IFilledFileFieldProps extends FilledTextFieldProps {
    onChangeValue: (value?: string) => void
}

export interface IOutlinedFileFieldProps extends OutlinedTextFieldProps {
    onChangeValue: (value?: string) => void
}

export type IFileFieldProps = IStandardFileFieldProps | IFilledFileFieldProps | IOutlinedFileFieldProps

export default function FileField(props: IFileFieldProps) {
    const classes = useStyles(props)

    const { value, onChangeValue, ...otherProps } = props

    const fileInputRef = React.useRef<HTMLInputElement>(null)

    const displayValue = React.useMemo(() => {
        if (props.value && typeof props.value === 'string') {
            return props.value.substring(0, 300) + '...'
        }
    }, [props.value])

    return (
        <>

            <TextField
                {...otherProps}
                InputProps={{
                    endAdornment: (
                        <IconButton
                            className={classes.adornment}
                            disabled={props.disabled}
                            onClick={() => {
                                if (fileInputRef.current) {
                                    fileInputRef.current.click()
                                }
                            }}
                        >
                            <AttachFileIcon/>
                        </IconButton>
                    ),
                }}
                value={displayValue || ''}
                onChange={props.onChange}
            />

            <input
                className={classes.hidden}
                ref={fileInputRef}
                type="file"
                onInput={() => {
                    if (fileInputRef.current) {
                        const files = fileInputRef.current.files
                        if (files && files.length) {
                            const file = files[0]
                            const reader = new FileReader()
                            reader.onloadend = () => {
                                onChangeValue(reader.result ? reader.result as string : undefined)
                            }
                            reader.readAsDataURL(file)
                        }
                    }
                }}
                tabIndex={-1}
            />

        </>
    )
}
