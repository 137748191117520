import React from 'react'

import { useParams } from 'react-router-dom'
import { fetchCode, updateCode, ICode } from '../../../../app-codes/services/CodesService'

import PageDialog from '../../../../../components/PageDialog/PageDialog'
import PageDialogHeader from '../../../../../components/PageDialog/PageDialogHeader'
import Container from '@material-ui/core/Container'
import CodelistItemForm from '../../CodelistItemForm/CodelistItemForm'
import Button from '@material-ui/core/Button'

export interface IUpdateItemProps {
    onClose: () => void
    onSuccess: (code: ICode) => void
}

export default function UpdateItem(props: IUpdateItemProps) {
    const { onClose, onSuccess } = props

    const { id } = useParams<{ id:string }>()

    const [ code, setCode ] = React.useState<Partial<ICode> | null>()
    const [ codePending, setCodePending ] = React.useState(true)

    React.useEffect(() => {
        setCodePending(true)
        fetchCode(id)
            .then((code) => {
                setCode(code)
                setCodePending(false)
            })
        ;
    }, [id])

    const [ submitPending, setSubmitPending ] = React.useState(false)

    const handleSubmit = () => {
        if (!code) {
            return
        }
        setSubmitPending(true)
        updateCode(id, code)
            .then((code) => {
                onSuccess(code)
            })
        ;
    }

    return (
        <PageDialog
            open
            onClose={onClose}
        >

            <PageDialogHeader
                onClose={onClose}
            >
                Изменить запись о сертификате
            </PageDialogHeader>

            {code && (
                <Container maxWidth="md">

                    <CodelistItemForm
                        data={code}
                        onChangeData={setCode}
                        disabled={submitPending}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={submitPending}
                        onClick={handleSubmit}
                    >
                        Сохранить запись
                    </Button>

                </Container>
            )}

        </PageDialog>
    )
}
