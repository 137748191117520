import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from './PageMain.styles'
//import clsx from 'clsx'

const useStyles = makeStyles(styles, {
    name: PageMain.name,
})

export interface IPageMainProps {
    classes?: any
    children: React.ReactNode
}

export default function PageMain(props: IPageMainProps) {
    const classes = useStyles(props)

    const { children } = props

    return (
        <main className={classes.root}>
            {children}
        </main>
    )
}
