import React from 'react'
//import makeStyles from '@material-ui/core/styles/makeStyles'
//import styles from './Codelist.styles'
//import clsx from 'clsx'

import { fetchCodelist, moveCodeUpward, moveCodeDownward, ICode } from '../../../app-codes/services/CodesService'
import { useHistory, useRouteMatch } from 'react-router-dom'

import PageHeader from '../../../../components/Page/PageHeader'
import PageHeaderTypography from '../../../../components/Page/PageHeaderTypography'
import PageProgress from '../../../../components/Page/PageProgress'
import PageMain from '../../../../components/Page/PageMain'
import PageFooter from '../../../../components/Page/PageFooter'
import PageFooterFab from '../../../../components/Page/PageFooterFab'

import List from '@material-ui/core/List'
import CodelistItem from '../CodelistItem/CodelistItem'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

import CreateItem from '../CodelistTool/CreateItem/CreateItem'
import UpdateItem from '../CodelistTool/UpdateItem/UpdateItem'
import DeleteItem from '../CodelistTool/DeleteItem/DeleteItem'

import { Switch, Route, NavLink } from 'react-router-dom'
import PageList from '../../../../components/Page/PageList'

//const useStyles = makeStyles(styles, {
//    name: Codelist.name,
//})

export interface ICodelistProps {
    formatItemUrl: (item: ICode) => string
}

export default function Codelist(props: ICodelistProps) {
    //const classes = useStyles()

    const { formatItemUrl } = props

    const [ codelist, setCodelist ] = React.useState<ICode[] | null>(null)
    const [ codelistPending, setCodelistPending ] = React.useState(true)

    React.useEffect(() => {
        if (!codelistPending) {
            return
        }
        let active = true
        fetchCodelist({})
            .then((codelist) => {
                if (active) {
                    setCodelist(codelist)
                    setCodelistPending(false)
                }
            })
        ;
        return () => {
            active = false
        }
    }, [codelistPending])

    const history = useHistory()
    const routeMatch = useRouteMatch()

    return (
        <>

            <PageHeader>
                <PageHeaderTypography>Сертификаты о вакцинации</PageHeaderTypography>
            </PageHeader>

            {codelistPending && (
                <PageProgress/>
            )}

            <PageMain>
                {codelist && (
                    <PageList>
                        {codelist.map((item) => (
                            <CodelistItem
                                key={item.id}
                                item={item}
                                formatItemUrl={formatItemUrl}
                                onMoveUpward={() => {
                                    moveCodeUpward(item.id)
                                        .then(() => {
                                            setCodelistPending(true)
                                        })
                                    ;
                                }}
                                onMoveDownward={() => {
                                    moveCodeDownward(item.id)
                                        .then(() => {
                                            setCodelistPending(true)
                                        })
                                    ;
                                }}
                            />
                        ))}
                    </PageList>
                )}
            </PageMain>

            <PageFooter>
                <PageFooterFab>
                    <Fab
                        color="primary"
                        component={NavLink}
                        to={`${routeMatch.url}/create`}
                    >
                        <AddIcon/>
                    </Fab>
                </PageFooterFab>
            </PageFooter>

            <Switch>

                <Route
                    path={`${routeMatch.path}/create`}
                    render={() => (
                        <CreateItem
                            onClose={() => {
                                history.goBack()
                            }}
                            onSuccess={() => {
                                history.goBack()
                                setCodelistPending(true)
                            }}
                        />
                    )}
                />

                <Route
                    path={`${routeMatch.path}/:id/update`}
                    render={() => (
                        <UpdateItem
                            onClose={() => {
                                history.goBack()
                            }}
                            onSuccess={() => {
                                history.goBack()
                                setCodelistPending(true)
                            }}
                        />
                    )}
                />

                <Route
                    path={`${routeMatch.path}/:id/delete`}
                    render={() => (
                        <DeleteItem
                            onClose={() => {
                                history.goBack()
                            }}
                            onSuccess={() => {
                                history.goBack()
                                setCodelistPending(true)
                            }}
                        />
                    )}
                />

            </Switch>

        </>
    )
}
