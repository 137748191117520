import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from './PageProgress.styles'
//import clsx from 'clsx'

import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles(styles, {
    name: PageProgress.name,
})

export interface IPageProgressProps {

}

export default function PageProgress(props: IPageProgressProps) {
    const classes = useStyles(props)

    return (
        <div className={classes.root}>
            <LinearProgress className={classes.progress} color="secondary"/>
        </div>
    )
}
