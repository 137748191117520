import { Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({

    root: {
        pointerEvents: 'none',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 32,
        '& > *': {
            pointerEvents: 'all',
        }
    },

})

export default styles
