import React from 'react'
//import makeStyles from '@material-ui/core/styles/makeStyles'
//import styles from './Codes.styles'
//import clsx from 'clsx'

import { useRouteMatch } from 'react-router-dom'

import { Switch, Route, Redirect } from 'react-router-dom'

import Code from '../../../app-codes-code/containers/Code/Code'
import Codelist from '../../../app-codes-codelist/containers/Codelist/Codelist'

export default function Codes() {
    //const classes = useStyles()

    const routeMatch = useRouteMatch()

    return (
        <>

            <Switch>

                <Route
                    path={`${routeMatch.path}/codelist`}
                    render={() => (
                        <Codelist
                            formatItemUrl={(item) => `${routeMatch.path}/code/${item.id}`}
                        />
                    )}
                />

                <Route
                    path={`${routeMatch.path}/code/:id`}
                    render={() => (
                        <Code/>
                    )}
                />

                <Redirect
                    to={`${routeMatch.path}/codelist`}
                />

            </Switch>

        </>
    )
}
