import { Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({

    adornment: {
        marginRight: theme.spacing(-1.5) + 2,
    },

})

export default styles
