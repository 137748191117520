import { v1 as uuid } from 'uuid'

export interface ICode {
    id: string
    name: string
    expiredAt?: string
    url?: string
    imageUrl?: string
}

export interface ICodelistQuery {
    id?: string
}

export async function fetchCodelist(query:ICodelistQuery): Promise<ICode[]> {
    let codelist: ICode[] = readCodelist()
    if (query) {
        if (query.id !== undefined) {
            codelist = codelist.filter((code) => code.id === query.id)
        }
    }
    return codelist
}

export async function fetchCode(id: string): Promise<ICode|null> {
    const [ code ] = await fetchCodelist({ id })
    return code || null
}

export async function createCode(data: Partial<ICode>) {
    if (!data.name) {
        throw Error()
    }
    const code: ICode = {
        ...data,
        id: uuid(),
        name: data.name,
    }
    const codelist: ICode[] = readCodelist()
    codelist.push(code)
    writeCodelist(codelist)
    return code
}

export async function updateCode(id: string, data: Partial<ICode>) {
    if (!data.name) {
        throw Error()
    }
    const code: ICode = {
        ...data,
        id,
        name: data.name,
    }
    let codelist: ICode[] = readCodelist()
    codelist = codelist.map((existsCode) => existsCode.id !== id ? existsCode : code)
    writeCodelist(codelist)
    return code
}

export async function deleteCode(id: string) {
    let codelist: ICode[] = readCodelist()
    codelist = codelist.filter((code) => code.id !== id)
    writeCodelist(codelist)
}

export async function moveCodeUpward(id: string) {
    let codelist: ICode[] = readCodelist()
    const i = codelist.findIndex(code => code.id === id)
    if (i > 0) {
        [codelist[i-1], codelist[i]] = [codelist[i], codelist[i-1]]
    }
    writeCodelist(codelist)
}

export async function moveCodeDownward(id: string) {
    let codelist: ICode[] = readCodelist()
    const i = codelist.findIndex(code => code.id === id)
    if (i < codelist.length - 1) {
        [codelist[i+1], codelist[i]] = [codelist[i], codelist[i+1]]
    }
    writeCodelist(codelist)
}

function readCodelist() {
    return localStorage.getItem('codelist') ? JSON.parse(localStorage.getItem('codelist')!) : []
}

function writeCodelist(codelist: ICode[]) {
    localStorage.setItem('codelist', JSON.stringify(codelist))
}
