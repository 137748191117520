import { Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({

    root: {
        paddingTop: 12,
        paddingBottom: 12,
    },

})

export default styles
