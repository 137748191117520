import { Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({

    root: {

    },

})

export default styles
