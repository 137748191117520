import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from './theme'
import CssBaseline from '@material-ui/core/CssBaseline'
import { HashRouter } from 'react-router-dom'

import App from './modules/app/containers/App/App'

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <HashRouter>

                <App/>

            </HashRouter>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

serviceWorkerRegistration.register()
