import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from './PageHeaderTypography.styles'
//import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(styles, {
    name: PageHeaderTypography.name,
})

export interface IPageHeaderTypographyProps {
    children: React.ReactNode
}

export default function PageHeaderTypography(props: IPageHeaderTypographyProps) {
    const classes = useStyles()

    const { children } = props

    return (
        <Typography
            className={classes.root}
            variant="h6"
            component="h1"
        >
            {children}
        </Typography>
    )
}
