import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from './Page.styles'
//import clsx from 'clsx'

import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Drawer from '@material-ui/core/Drawer'
import PageHeaderTypography from './PageHeaderTypography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import SvgIcon from '@material-ui/core/SvgIcon'

import { NavLink } from 'react-router-dom'

import logoUrl from '../../logo.svg'

const useStyles = makeStyles(styles, {
    name: Page.name,
})

export interface IPageContextValue {
    content: React.ReactNode
    setContent: (toolbar: React.ReactNode) => void
}

export const PageContext = React.createContext<IPageContextValue | null>(null)

export interface IPageProps {
    children: React.ReactNode
}

export default function Page(props: IPageProps) {
    const classes = useStyles()

    const { children } = props

    const [ menuOpen, setMenuOpen ] = React.useState(false)

    const [ content, setContent ] = React.useState<React.ReactNode>(null)

    const value = React.useMemo<IPageContextValue>(() => {
        return {
            content,
            setContent,
        }
    }, [
        content,
        setContent,
    ])

    return (
        <PageContext.Provider value={value}>

            <AppBar position="sticky" color="default" elevation={1}>
                <Container className={classes.headerContainer} maxWidth={false}>
                    <Toolbar disableGutters>

                        <IconButton
                            className={classes.headerMenu}
                            color="inherit"
                            edge="start"
                            onClick={() => setMenuOpen(true)}
                        >
                            <MenuIcon/>
                        </IconButton>

                        {content}

                    </Toolbar>
                </Container>
            </AppBar>

            <Drawer
                anchor="left"
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
            >

                <AppBar position="sticky" color="default" elevation={1}>
                    <Container maxWidth={false}>
                        <Toolbar disableGutters>

                            <img
                                className={classes.menuLogo}
                                src={logoUrl}
                                alt="Covidcode"
                            />

                            <PageHeaderTypography>
                                Covidcode
                            </PageHeaderTypography>

                        </Toolbar>
                    </Container>
                </AppBar>

                <div className={classes.menuMain}>

                    <List className={classes.menuList}>

                        <ListItem
                            className={classes.menuListItem}
                            button
                            component={NavLink}
                            to={`/codes/codelist`}
                            onClick={() => setMenuOpen(false)}
                        >
                            <ListItemIcon className={classes.menuListItemIcon}>
                                <SvgIcon>
                                    <path fill="currentColor" d="M2 14H8V20H2M16 8H10V10H16M2 10H8V4H2M10 4V6H22V4M10 20H16V18H10M10 16H22V14H10"/>
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText
                                className={classes.menuListItemText}
                                primary="Сертификаты о вакцинации"
                            />
                        </ListItem>

                        <ListItem
                            className={classes.menuListItem}
                            button
                            component={NavLink}
                            to={`/config`}
                            onClick={() => setMenuOpen(false)}
                        >
                            <ListItemIcon className={classes.menuListItemIcon}>
                                <SvgIcon>
                                    <path fill="currentColor" d="M3,17V19H9V17H3M3,5V7H13V5H3M13,21V19H21V17H13V15H11V21H13M7,9V11H3V13H7V15H9V9H7M21,13V11H11V13H21M15,9H17V7H21V5H17V3H15V9Z" />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText
                                className={classes.menuListItemText}
                                primary="Настройки"
                            />
                        </ListItem>

                    </List>

                    <List className={classes.menuList}>

                        <ListItem
                            className={classes.menuListItem}
                            button
                            component={NavLink}
                            to={`/about`}
                            onClick={() => setMenuOpen(false)}
                        >
                            <ListItemIcon className={classes.menuListItemIcon}>
                                <SvgIcon>
                                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText
                                className={classes.menuListItemText}
                                primary="О программе"
                            />
                        </ListItem>

                    </List>

                </div>

            </Drawer>

            {children}

        </PageContext.Provider>
    )
}
