import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from './Config.styles'

import { fetchConfig, IConfig } from '../../services/ConfigService'

import PageHeader from '../../../../components/Page/PageHeader'
import PageHeaderTypography from '../../../../components/Page/PageHeaderTypography'
import PageMain from '../../../../components/Page/PageMain'

const useStyles = makeStyles(styles, {
    name: Config.name,
})

export default function Config() {
    const classes = useStyles()

    return (
        <>

            <PageHeader>
                <PageHeaderTypography>Настройки</PageHeaderTypography>
            </PageHeader>

            <PageMain>
                Настройки
            </PageMain>

        </>
    )
}
