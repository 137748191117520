import { Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({

    adornment: {
        marginRight: theme.spacing(-1.5) + 2,
    },

    hidden: {
        position: 'absolute',
        visibility: 'hidden',
        width: 1,
        height: 1,
    },

})

export default styles
