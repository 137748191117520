import React from 'react'

import Page from '../../../../components/Page/Page'

import { Switch, Redirect, Route } from 'react-router-dom'

import About from '../../../app-about/containers/About/About'
import Codes from '../../../app-codes/containers/Codes/Codes'
import Config from '../../../app-config/containers/Config/Config'

export interface IAppProps {

}

export default function App(props: IAppProps) {

    return (
        <Page>

            <Switch>

                <Route path={`/about`} component={About}/>

                <Route path={`/codes`} component={Codes}/>

                <Route path={`/config`} component={Config}/>

                <Route
                    render={() => {
                        const path = localStorage.getItem('path')
                        if (path) {
                            return (
                                <Redirect to={path}/>
                            )
                        } else {
                            return (
                                <Redirect to={`/codes`}/>
                            )
                        }
                    }}
                />

            </Switch>

        </Page>
    )
}
