import React from 'react'

import { useParams } from 'react-router-dom'
import { deleteCode } from '../../../../app-codes/services/CodesService'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

export interface IDeleteItemProps {
    onClose: () => void
    onSuccess: () => void
}

export default function DeleteItem(props: IDeleteItemProps) {
    const { onClose, onSuccess } = props

    const { id } = useParams<{ id:string }>()

    const handleConfirm = () => {
        deleteCode(id)
            .then(() => {
                onSuccess()
            })
        ;
    }

    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <DialogTitle id="alert-dialog-title">
                Удалить запись
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Действительно удалить запись о сертификате?
                </DialogContentText>
            </DialogContent>

            <DialogActions>

                <Button
                    onClick={onClose}
                    autoFocus
                >
                    Отмена
                </Button>

                <Button
                    onClick={handleConfirm}
                >
                    Удалить
                </Button>

            </DialogActions>

        </Dialog>
    )
}
