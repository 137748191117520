import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from './CodelistItem.styles'
import clsx from 'clsx'

import { ICode } from '../../../app-codes/services/CodesService'
import { useRouteMatch } from 'react-router-dom'

import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { NavLink } from 'react-router-dom'

import { compareAsc } from 'date-fns'

const useStyles = makeStyles(styles, {
    name: CodelistItem.name,
})

export interface ICodelistItemProps {
    item: ICode
    formatItemUrl: (item: ICode) => string
    onMoveUpward: () => void
    onMoveDownward: () => void
}

export default function CodelistItem(props: ICodelistItemProps) {
    const classes = useStyles(props)

    const { item, formatItemUrl, onMoveUpward, onMoveDownward } = props

    const [ menuOpen, setMenuOpen ] = React.useState(false)
    const menuAnchorRef = React.useRef<HTMLButtonElement>(null)

    const routeMatch = useRouteMatch()

    const status = React.useMemo(() => {
        if (item.expiredAt) {
            if (compareAsc(new Date(item.expiredAt), new Date) > 0) {
                return (
                    <Typography className={clsx(classes.status, classes.status_valid)} variant="body2" component="span">
                        Действителен
                    </Typography>
                )
            } else {
                return (
                    <Typography className={clsx(classes.status, classes.status_invalid)} variant="body2" component="span">
                        Просрочен
                    </Typography>
                )
            }
        } else {
            return (
                <Typography className={clsx(classes.status)} color="textSecondary" variant="body2" component="span">
                    Срок действия не указан
                </Typography>
            )
        }
    }, [item])

    return (
        <>

            <ListItem
                className={classes.root}
                button
                component={NavLink}
                to={formatItemUrl(item)}
            >

                <ListItemText
                    disableTypography
                    primary={(
                        <div className={classes.text}>
                            <Typography variant="body1" color="textPrimary" component="span">{item.name}</Typography>{item.url && (<Typography variant="body1" color="textSecondary" component="span">&nbsp;&mdash;&nbsp;{item.url}</Typography>)}
                        </div>
                    )}
                    //secondary={(
                    //    <div className={classes.itemDesc}>
                    //        {status}
                    //    </div>
                    //)}
                />

                <ListItemSecondaryAction>
                    <IconButton
                        ref={menuAnchorRef}
                        edge="end"
                        onClick={() => setMenuOpen(true)}
                    >
                        <MoreVertIcon/>
                    </IconButton>
                </ListItemSecondaryAction>

            </ListItem>

            {menuAnchorRef.current && (
                <Menu
                    anchorEl={menuAnchorRef.current}
                    open={menuOpen}
                    onClose={() => setMenuOpen(false)}
                >

                    <MenuItem
                        onClick={() => { setMenuOpen(false); onMoveUpward() }}
                    >
                        <ListItemText
                            primary="Переместить выше"
                        />
                    </MenuItem>

                    <MenuItem
                        onClick={() => { setMenuOpen(false); onMoveDownward() }}
                    >
                        <ListItemText
                            primary="Переместить ниже"
                        />
                    </MenuItem>

                    <MenuItem
                        component={NavLink}
                        to={`${routeMatch.path}/${item.id}/update`}
                        onClick={() => setMenuOpen(false)}
                    >
                        <ListItemText
                            primary="Изменить"
                        />
                    </MenuItem>

                    <MenuItem
                        component={NavLink}
                        to={`${routeMatch.path}/${item.id}/delete`}
                        onClick={() => setMenuOpen(false)}
                    >
                        <ListItemText
                            primary="Удалить"
                            primaryTypographyProps={{
                                color: 'error'
                            }}
                        />
                    </MenuItem>

                </Menu>
            )}

        </>
    )
}
