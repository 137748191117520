import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from './DateField.styles'

import DateFnsUtils from '@date-io/date-fns'
import ruLocale from "date-fns/locale/ru"
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import EventIcon from '@material-ui/icons/Event'
import Dialog from '@material-ui/core/Dialog'

const useStyles = makeStyles(styles, {
    name: DateField.name,
})

export interface IDateFieldProps {
    value?: string | null
    onChangeValue: (value?: Date | null) => void
}

export default function DateField(props: IDateFieldProps) {
    const classes = useStyles(props)

    const { value, onChangeValue } = props

    const [ pickerOpen, setPickerOpen ] = React.useState(false)

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>

            <TextField
                label="Срок действия до"
                variant="outlined"
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <IconButton
                            className={classes.adornment}
                            edge="end"
                            onClick={() => setPickerOpen(true)}
                        >
                            <EventIcon/>
                        </IconButton>
                    )
                }}
            />

            <Dialog
                open={pickerOpen}
                onClose={() => setPickerOpen(false)}
            >
                <DatePicker
                    variant="static"
                    value={value}
                    onChange={onChangeValue}
                />
            </Dialog>

        </MuiPickersUtilsProvider>
    )
}
