import { Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({

    root: {
        position: 'sticky',
        bottom: 0,
        pointerEvents: 'none',
        '& > *': {
            pointerEvents: 'all',
        }
    },

})

export default styles
