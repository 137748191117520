import { Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({

    root: {

    },

    main: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16,
        overflow: 'hidden',
    },
    mainImage: {
        display: 'block',
        maxWidth: 250,
        maxHeight: 250,
        '@media (orientation: landscape)': {
            height: '100%',
        },
        '@media (orientation: portrait)': {
            width: '100%',
        },
    },
    mainImage_empty: {
        '& > path[fill]': {
            fill: theme.palette.action.disabled,
        },
    },

    footerToolbar: {
        justifyContent: 'center',
    },
    footerTabs: {

    },
    footerTab: {
        height: 64,
    },

})

export default styles
